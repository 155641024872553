
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of members</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportSelected()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
              </template>
              <template v-slot:head(check)>
                <div style="width:3.5rem;" class="form-check text-center">
                  <label class="form-check-label me-2" for="tableSRadioHead">
                    <input @change="selectAllRows($event)" type="checkbox"
                      class="form-check-input" id="tableSRadioHead" />
                  </label>
                </div>
              </template>
              <template v-slot:cell(check)="data">
                <div style="width:3.5rem;" class="form-check text-center me-auto">
                  <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                    :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(user_image)="data">
                <div class="cursor-pointer position-relative">
                  <img @click="showProfileImg(data.item.user_image)" class="avatar rounded-circle" :src="absoluteUrl(data.item.user_image)" />
                </div>
              </template>
              <template v-slot:cell(user_first_name)="data">
                <div style="min-width:8rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.user_first_name}} {{ data.item.user_last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(user_mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.user_mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(group_name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.group_name }}</span>
                </div>
              </template>
              <template v-slot:cell(connect_unit)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.connect_unit }}</span>
                </div>
              </template>
              <template v-slot:cell(voted_in_2023)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-if="data.item.voted_in_2023" class="badge bg-primary"> YES </span>
                  <span v-else class="badge bg-danger"> NO </span>
                </div>
              </template>
              <template v-slot:cell(residential_address)="data">
                <div style="min-width:20rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.residential_street_address }}</span> <br>
                  <span class="text-dark font-sm" v-if="data.item.residential_lga_name">{{ data.item.residential_lga_name }}</span>,
                  <span class="text-dark font-sm" v-if="data.item.residential_state_name">{{ data.item.residential_state_name }}</span>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="width:7rem;" class="cursor-pointer">
                  {{$filters.date(data.item.created_at, 1) }} 
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import { commonSortingMixin } from '@/mixins/commonSortingMixin';
import { commonPaginationMixin } from '@/mixins/commonPaginationMixin';
import { commonSelectRowMixin } from '@/mixins/commonSelectRowMixin';

export default {
  name: "members",
  components:{
    IsLoading,
  },
  mixins: [
    commonSortingMixin,
    commonPaginationMixin,
    commonSelectRowMixin
  ],
  props:{
    groupId:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      searchQuery:"",
      columns: [
        {
          key: "check",
        },
        {
          key: "user_image",
          label: "Image",
        },
        {
          key: "user_first_name",
          label: "Name",
          sortable: true
        },
        {
          key: "user_mobile",
          label: "Phone",
        },
        {
          key: "group_name",
          label: "Group",
        },
        {
          key: "connect_unit",
          label: "Connect Unit",
          sortable: true
        },
        {
          key: "voted_in_2023",
          label: "Previous Voter",
          sortable: true
        },
        {
          key: "residential_address",
          label: "Residential Address",
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: true
        },
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "desc"
      },
      pageData:{
        data: []
      }
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/members/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.groupId){ payload.group_id = this.groupId }
      this.isLoading = true
      this.$http.get(`/members?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    exportSelected() {
      if(!this.selectedItems.length){
        this.alertError("Select the members you'd like to export."); return;
      }
      const json = this.pageData.data
                    .filter(cd => this.selectedItems.includes(cd.id))
                    .map(item => {
                      return Object.keys(item).reduce((acc, key) => {
                        let formattedKey = key.replace(/_/g, " ") // Optional: Format key names
                                        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize words

                        if(key.includes("_at")){
                          acc[formattedKey] = this.$filters.date(item[key], 1)
                        }else if(key == 'friendly_id'){
                          acc['applicantId'] = item[key]
                        }else{
                          acc[formattedKey] = item[key];
                        }
                        return acc;
                      }, {});
                    })
        const csvString = this.jsonToCSV(json)
        this.downloadCSV(csvString, "members.csv")
    },
    initializeAll(){
      this.selectedItems = [];
      this.lastCheckedIndex = null
    }
  },
  created() {
    this.fetchItems()
  }
}

</script>

